.p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
}
.exporttext a {
    color: white;
    font-size: 1.1rem;
}
.exporttext {
    display: inline;
    padding: 11px;
    background: green;
    border-radius: 5px;
    position: relative;
    bottom: 2px;
}

/* .crud .p-datatable .p-datatable-thead > tr > th{
    text-align: center;
} */
.ListTable {
    display: none;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.show {
    display: block;
}
.dropdown-content a:hover {
    background-color: #ddd;
}
.newbtnhover:hover {
    background-color: #689f38 !important;
}

.deletebtnhover:hover {
    background-color: #fbc02d !important;
}

.p-disabled,
.p-component:disabled,
.p-dropdown.p-disabled,
.p-calendar-disabled,
.p-calendar-w-btn.p-calendar-disabled {
    opacity: 0.7;
    font-weight: 600;
    background: #f5f5f5 no-repeat;
    background-image: none;
}

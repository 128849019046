/* .p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left !important;
} */
.highlight-red {
    background-color: #dd0000;
    color: white;
}
.highlight-orange {
    background-color: rgb(255, 166, 0);
    color: #000;
}

.highlight-red .customer-badge {
    color: white;
}
.highlight-orange .customer-badge {
    color: #000;
}

.p-button {
    justify-content: center;
}

.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.p-datatable .p-datatable-thead > tr > th {
    font-weight: 700;
}
.formstemplate .p-avatar img {
    object-fit: cover;
}

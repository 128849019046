@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600);
.snip1336 {
  font-family: "Roboto", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 3px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #1e1e1e;
  text-align: left;
  line-height: 1.4em;
  /* background-color: #141414; */
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.snip1336 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1336 img {
  max-width: 100%;
  vertical-align: top;
  opacity: 0.85;
}
.snip1336 figcaption {
  width: 100%;
  background-color: #ffffff;
  padding: 25px;
  position: relative;
}
.snip1336 figcaption:before {
  position: absolute;
  content: "";
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #141414;
}
.snip1336 figcaption a {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  opacity: 0.65;
  width: 47%;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
}
.snip1336 figcaption a:hover {
  opacity: 1;
}
.snip1336 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 100%;
  left: 25px;
  z-index: 1;
  max-width: 90px;
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.snip1336 .follow {
  margin-right: 4%;
  border-color: #2980b9;
  color: #2980b9;
}
.snip1336 h2 {
  margin: 0 0 5px;
  font-weight: 300;
}
.snip1336 h2 span {
  display: block;
  font-size: 0.5em;
  color: #2980b9;
}
.snip1336 p {
  margin: 0 0 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  opacity: 0.8;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
}
.exporttext a {
  color: white;
  font-size: 1.1rem;
}
.spd-report .exporttext {
  display: inline;
  padding: 10px;
  background: green;
  border-radius: 5px;
}

.p-toolbar.spd-removebg {
  background-color: transparent;
}

.usagetable .p-datatable-header {
  padding: 0;
}
.usagetable .p-datatable-header h5 {
  margin: 0;
}
.spd-success {
  background-color: #8bc34a !important;
  color: #fff !important;
}

.spd-info {
  background-color: #03a9f4 !important;
  color: #fff !important;
}
.spd-collapse {
  background-color: #e9ebc08c !important;
  color: #000 !important;
}
.spd-warn {
  background-color: #ffc107 !important;
  color: #000 !important;
}

.spd-error {
  background-color: #f44336 !important;
  color: #fff !important;
}
/* Apply alternate row styling */
.usagetable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #e9ebc08a; /* Change this to your desired alternate row color */
}

.spd-secondary,
.reimbursement {
  background-color: #e0e0e0 !important;
  color: #000 !important;
}

.spd-primary {
  background-color: #2196f3 !important;
  color: #fff !important;
}

.spd-light {
  background-color: #f5f5f5 !important;
  color: #000 !important;
}

.spd-dark {
  background-color: #212121 !important;
  color: #fff !important;
}
.fines {
  color: #333533 !important;
  background-color: #970e211f !important;
  min-width: 81px;
  flex-grow: 1;
}
.salik {
  color: #333533 !important;
  background-color: #e142471c !important;
  min-width: 81px;
  flex-grow: 1;
}
.fuel {
  color: #333533 !important;
  background-color: #fa65072e !important;
  min-width: 81px;
  flex-grow: 1;
}

.repair {
  color: #333533 !important;
  background-color: rgba(246, 189, 96, 0.361) !important;
  min-width: 81px;
  flex-grow: 1;
}
.parking {
  color: #333533 !important;
  background-color: rgba(211, 211, 211, 0.7490196078);
  min-width: 81px;
  flex-grow: 1;
}

.others {
  color: #333533 !important;
  background-color: rgba(56, 176, 0, 0.141) !important;
  min-width: 81px;
  flex-grow: 1;
}

.reportview:hover {
  background-color: unset;
}
.reportview:enabled:focus,
.reportview:not(button):not(a):not(.p-disabled):focus {
  background-color: #0db2f0;
}
.Vehicleprofile {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.vehicleBox:hover {
  transform: translateY(-2px);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease-in-out;
}

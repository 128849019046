/* $large:1496px;
 $Medium:1400px; */
.chartWidth .p-chart {
    width: 80% !important;
}

/* .p-chart{
   
} */
.ms-Icon {
    color: #0097a7;
    font-size: 1.4rem;
}
.expensesGainIcon {
    color: #1cac78;
    font-weight: 600;
}
.expensesLossIcon {
    color: red;
    font-weight: 600;
}
/* .layout-content{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
} */
.approvaltable .p-datatable .p-datatable-thead > tr > th {
    padding: 0.75rem 0.75rem;
}
/* .ExpenseTitle {
    margin-bottom: 0px !important;
} */
.approvaltable .p-datatable .p-datatable-thead > tr > th {
    text-align: center !important ;
    padding-right: 3px;
    padding-left: 3px;
}
.approvaltable .p-datatable .p-datatable-tbody > tr > td {
    text-align: center !important ;
    padding-right: 3px;
    padding-left: 3px;
}
.headerWidth {
    width: 8%;
}
.navicons .pi {
    font-size: 1.4rem;
}
@media only screen and (max-width: 1537px) {
    .chartWidth .p-chart {
        width: 80% !important;
    }
}
@media only screen and (min-width: 1538px) {
    .chartWidth .p-chart {
        width: 50% !important;
    }
}
.external {
    display: table;
    position: relative;
    top: 0;
    left: 0;
    height: 90%;
    width: 100%;
}
.middle {
    display: table-cell;
    vertical-align: middle;
}
.internal {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    text-align: center;
    font-size: 1.2rem;
}
.consumption-footer {
    margin-left: 18px;
}
.consumption-notify {
    padding: 2px;
    position: relative;
    bottom: 2px;
}

.dateFilter .p-inputtext {
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1.5px solid #a4a4a4;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 4px;
    cursor: pointer;
}
.dateFilter .p-inputtext:enabled:hover {
    background-color: #ececec;
    border-color: transparent;
    background-image: unset;
}
.clrbtn {
    border: none;
    background: white;
    cursor: pointer;
    vertical-align: middle;
}
.MaintenanceReportTable .p-datatable-tbody > tr > td {
    font-size: 13.4px;
    font-weight: 600;
    /* text-align: left !important; */
}
.MaintenanceReportTable .p-datatable-tbody > tr {
    color: #000000c2;
}
input[type='text']::placeholder {
    text-align: center;
    color: black; /* for Chrome, Firefox, Opera */
}
:-ms-input-placeholder {
    text-align: center; /* for IE 10-11 */
}
::-webkit-input-placeholder {
    text-align: center; /* for IE Edge */
}
.MaintenanceReportTable .p-datatable .p-datatable-thead > tr > th {
    /* text-align: left; */
    padding-right: 5px;
    padding-left: 5px;
    /* width: 9%; */
}

.atag {
    color: blue;
}
/* .MaintenanceReportTable .p-datatable .p-datatable-thead > tr > th{
    background: #96c3ef8c;
} */
.TotalColorcondition {
    background: #ffcdd2;
    color: #c63737;
    padding: 0.25em 0.5rem;
    border-radius: 5px;
}
.TotalColor {
    color: #000000c2;
}

/* Apply a modern color scheme */
.driver-container-card {
    background-color: #f2f5f8;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add icons to the information */
.driver-container-card .icon {
    margin-right: 8px;
}

/* Add a subtle hover effect */
.driver-container-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.container-header {
    margin-bottom: 16px;
}

.driver-container-card {
    background-color: #f2f5f8;
    border-radius: 8px;
    padding: 16px;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.driver-info {
    display: flex;
    flex-direction: column;
}
.driver {
    display: flex;
    align-items: center;
}
.driver .driver_name {
    font-weight: bold;
}
.driver img,
.driver .image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 45px;
}

.driver,
.driver-department,
.driver-licenseno,
.driver-licensetype {
    margin-bottom: 8px;
}

.ListTable {
    display: none;
}
.appoinment .p-datatable .p-datatable-thead > tr > th,
.appoinment .p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
}

#newform {
    border-right: 1px solid #eee;
}

.spd-card-footer {
    border-top: 1px solid #eee;
    padding-top: 1rem;
}
#addexpensebtn button {
    background: #0db2f0;
    color: white;
    border: none;
}
#totalamountid,
#approverdiv {
    /* position: absolute;
    right: 60px; */
    font-weight: 600;
    border: 1px solid #dadada;
    padding: 6px 10px;
    color: rgba(0, 0, 0, 0.87);
}

DataTableDemo.css

.datatable-rowgroup-demo .p-rowgroup-footer td {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
}
         
.status-Documents{
    background: #ECCFFF;
    color: #694382;
}
.customer-badge, .product-badge, .order-badge{
    border-radius: var(--border-radius);
    padding: 0.40em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}
.status-Driver{
    background: #B3E5FC;
    color: #23547B;
}
.status-pages{
    background: #FFD8B2;
    color: #805B36;
}
.status-Vehicle{
    background: #C8E6C9;
    color: #256029;
}
.HeaderWidth{
    width: 8%;
}
.PermissionTable .p-datatable .p-datatable-tbody > tr > td{
    font-weight: 500;
    font-size: 13px;
}
.Permission .field-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.PiIcons{
    position: relative;
    top: 5px;
    margin-right: 5px;
}
.PermissionTable .p-datatable .p-datatable-thead > tr > th{
    text-align: center;
}
input[type=checkbox] {
    accent-color: #3F51B5;
  }
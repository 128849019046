.Renewal .p-message-summary {
    padding-right: 10px;
}
.status-highlight-red {
    background: #ffcdd2;
    color: #c63737;
}
.status-highlight-orange {
    background: #ffd8b2;
    color: #805b36;
}
.export_renewals {
    padding: 10px;
    background: green;
    border-radius: 5px;
}

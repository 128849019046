$large: 1450px;

// .p-datatable .p-datatable-tbody > tr > td{
//     text-align: center !important;
// }
.p-datatable .p-column-header-content {
    display: unset !important;
}
.Workspace .p-datatable .p-datatable-thead > tr > th {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
}
// .Workspace .p-datatable .p-datatable-tbody > tr > td {
//     text-align: center !important;
// }
.datatable-rowexpansion-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
    padding: 1rem;
}
.layout-wrapper .layout-main .layout-content {
    padding: 1rem;
}
.datatable-rowexpansion-demo .orders-subtable {
    padding: 1rem;
}
.spd-card-footer {
    border-top: 1px solid #eee;
    padding-top: 1rem;
    text-align: end;
}
.layout-wrapper .layout-main .layout-content {
    padding: 1rem;
}
.Workspace .p-datatable .p-datatable-tbody > tr > td {
    // padding: 1.75rem 0.75rem;
    // padding: 0.50rem 0.75rem;
    padding-right: 4.5px;
    padding-left: 4.5px;
}
.workspace.p-button {
    font-size: 13px;
}
.inputbtn {
    font-size: 13px;
    font-weight: 600;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    /* font-size: 1rem; */
    color: #495057 !important;
    background: #ffffff !important;
    /* padding: 0.75rem 0.75rem; */
    border: 1px solid #ced4da !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none !important;
    border-radius: 6px !important;
}
// .workspacehover:hover{
//     border: 1px solid #2196f3;
// }
// .workspacehover.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover{
//     background: rgba(33, 166, 223, 0);
//     color: #2196f3;
//     border: 2px solid #2196f3;
// }
.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
    background: #0db2f0;
}
.dropdown.p-dropdown {
    width: 14rem;
}
.dropdownstylename .p-input-filled .drivernameoption {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}
.dropdown.country-item-value img.flag {
    width: 17px;
}
.stylename .p-dropdown .p-inputtext {
    color: white;
    font-weight: 500;
    padding: 7px;
}
// .stylename.p-dropdown .p-inputtext, .p-dropdown .p-dropdown-trigger{
//     color: white;
//     width: 1.50rem;
// }
.stylename .p-dropdown {
    width: 97px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid transparent;
    /* background: #f5f5f5 no-repeat; */
    /* background-image: linear-gradient(to bottom, #3F51B5, #3F51B5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)); */
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    background: #0db2f0;
}
.stylename .p-dropdown:not(.p-disabled):hover {
    background-color: #fffafa00;
    border-color: transparent;
    background-image: unset;
    border: 2px solid #2196f3;
    .p-inputtext {
        color: #0db2f0;
        font-weight: 500;
    }
    .p-dropdown-trigger {
        color: #0db2f0;
    }
}
.stylename .p-dropdown:not(.p-disabled):focus {
    background-color: #fffafa00;
    border-color: transparent;
    background-image: unset;
    border: 2px solid #2196f3;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #0db2f0;
    background: #00b9ff1c;
}
.headerwidth {
    width: 0%;
}
@media (min-width: $large) {
    .headerwidth {
        width: unset;
    }
}
.bgcolors {
    background: #f6bd605c !important;
    color: #030303 !important;
}
.expiringSoon {
    background: none !important;
    color: white;
}
.accidents {
    // border: 2px solid #d90429 !important ;
    color: #333533 !important;
    background-color: rgba(47, 62, 70, 0.1411764706) !important;
    min-width: 78px;
}
.fines {
    // border: 2px solid #970e21 !important ;
    color: #333533 !important;
    background-color: #970e211f !important;
    min-width: 81px;
}
.salik {
    // border: 2px solid #e14247 !important ;
    color: #333533 !important;
    background-color: #e142471c !important;
    min-width: 81px;
}
.mileage {
    // border: 2px solid #38b000 !important ;
    color: #333533 !important;
    background-color: #38b00024 !important;
    min-width: 81px;
}
.parking {
    // border: 2px solid #fa6507 !important ;
    color: #333533 !important;
    background-color: #d3d3d3bf !important;
    min-width: 81px;
}
.vipfuel {
    color: #333533 !important;
    background-color: #fa65072e !important;
    min-width: 81px;
}
.accidents {
    color: #333533 !important;
    background-color: #2f3e4624 !important;
    min-width: 81px;
}
.vehicleid {
    background: #f8f9fa !important;
    color: #333533 !important;
}
.workspacedialog .p-dialog-title {
    width: 200px;
}
.editabledropdown {
    width: 100%;
    background: #0db2f0 !important;
}

.editabledropdown .p-inputtext {
    color: white;
    font-weight: 500;
}
.homeNews__search {
    // background: white;
    // border-radius: 3px;
    // border: 1px solid black;
    // outline: 0;
    // width: 250px;
    // height: 41px;
    // padding-left: 15px;
    // position: relative;
    // right: 35px;
    padding: 10px 35px 10px 15px;
    font-size: 14px;
    border: 1px solid #aaa;
}
.homeNews__search:focus {
    outline: 0;
    box-shadow: 0 0 4px #39f;
    // text-align: left;
    // padding-left: 10px;
}
.contentBox__searchClearBtn {
    // position: absolute;
    // top: 7px;
    // left: 194px;
    width: 25px;
    height: 25px;
    color: #fff;
    font-weight: 700;
    border-radius: 15px;
    border: none;
    background: #0a2885;
    cursor: pointer;
    margin-top: 7px;
}
.rowEdit {
    width: 20px;
}
.text-center {
    text-align: center;
}
.badge-size {
    min-width: 1.5rem !important;
    line-height: 1.5rem !important;
    height: 1.5rem !important;
}
.container-space {
    position: 'relative';
    margin-top: 1em;
    margin-bottom: 1em;
}
.expiring-date {
    color: white;
}
.avatar-badge .p-avatar {
    width: 1.5rem;
    height: 1.5rem;
}
.avatar-badge .p-badge {
    font-weight: 600;
}
.avatar-badge .p-overlay-badge .p-badge {
    top: -10px;
    right: -6px;
}
.avatar-badge .p-overlay-badge .ScheduleExpire {
    top: -1px;
    right: 0px;
}
//
.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 9999;
    min-width: 90px;
    min-height: 29px;
    line-height: 29px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 3px;
}

.insuranceExpiring:hover .tooltip-text {
    visibility: visible;
}
.expiringWarranty:hover .tooltip-text {
    visibility: visible;
}
.registrionExpiring:hover .tooltip-text {
    visibility: visible;
}
.expiringTRA:hover .tooltip-text {
    visibility: visible;
}
.expiringServiceContract:hover .tooltip-text {
    visibility: visible;
}
div[data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    color: #000;
    padding: 5px;
    display: none;
}

div[data-tooltip]:hover::before {
    display: block;
}
.logs-overlay-height {
    height: 350px;
    overflow: auto;
}
.stylename .p-button-label {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

input[type='file']::file-selector-button {
    /* border: 2px solid #6c5ce7;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #a29bfe;
    transition: 1s; */
    color: #ffffff;
    background: #0db2f0;
    border: 0 none;
    padding: 0.643rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
}

input[type='file']::file-selector-button:hover {
    background: rgba(33, 166, 223, 0);
    color: #2196f3;
    border: 2px solid #2196f3;
}
input[type='file'] {
    color: #b8b8b8;
    font-size: 15px;
}

.spd-pemptytopbottom {
    /* padding-top: 0px;
    padding-bottom: 0px; */
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    background-color: transparent;
}

.container-card .card {
    box-shadow: none;
}
.container-header {
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.DocTitle {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.batchStyle {
    cursor: pointer;
    font-size: 0.95rem !important;
}
